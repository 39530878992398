import * as React from "react";

export const About = () => { 
    return (
        <>
            <div className="page-info page-about">
                About page
            </div>
        </>
    )
}