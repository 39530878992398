import * as React from "react";

export const Services = () => { 
    return (
        <>
            <div className="page-info page-services">
                Services page
            </div>
        </>
    )
}